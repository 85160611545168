import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../components/footer';
import { Layout } from '../components/layout';
import { PageHeader } from '../components/page-header';
import * as css from './project.module.css';

const ProjectTemplate = ({ data }) => {
  const { post, site, previewImage } = data;

  return (
    <Layout className={css.wrapper}>
      <Helmet>
        <title>
          {post.frontmatter.title} - {site.siteMetadata.title}
        </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@dandean" />
        <meta name="twitter:creator" content="@dandean" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        {post.frontmatter.description ? (
          <meta
            name="twitter:description"
            content={post.frontmatter.description}
          />
        ) : null}
        {previewImage?.childImageSharp ? (
          <meta
            name="twitter:image"
            content={`${site.siteMetadata.domain}${previewImage.childImageSharp.resize.src}`}
          />
        ) : null}

        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:site_name" content={site.siteMetadata.title} />
        {previewImage?.childImageSharp
          ? [
              <meta
                key={`image-${previewImage.id}`}
                property="og:image"
                content={`${site.siteMetadata.domain}${previewImage.childImageSharp.resize.src}`}
              />,
              <meta
                key="imageHeight"
                property="og:image:height"
                content={previewImage.childImageSharp.resize.height}
              />,
              <meta
                key="imageWidth"
                property="og:image:width"
                content={previewImage.childImageSharp.resize.width}
              />,
            ]
          : null}
      </Helmet>
      <PageHeader
        variant="project"
        title={post.frontmatter.title}
        categoryLabel={post.frontmatter.category}
      />
      <main dangerouslySetInnerHTML={{ __html: post.html }} />
      <Footer />
    </Layout>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectQuery($slug: String!, $image: String!) {
    site {
      siteMetadata {
        title
        domain
      }
    }
    simpleLogo: imageSharp(original: { src: { regex: "/simple/" } }) {
      gatsbyImageData(quality: 50, width: 40, height: 40, placeholder: NONE)
    }
    previewImage: file(relativePath: { eq: $image }) {
      id
      childImageSharp {
        resize(width: 1200, height: 600) {
          src
          width
          height
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        employer
        description
        category
      }
    }
  }
`;
